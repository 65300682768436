<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      height="80"
      color="white"
    >
      <base-img
        :src="require('@/assets/logo-istpro.png')"
        class="mr-2"
        contain
        max-width="100"
        width="100%"
      />
      <!-- <span class="font-weight-bold primary--text hidden-sm-and-down">#MantapBerhijrah</span> -->
      <v-spacer />

      <div>
        <v-tabs
          v-model="active_tab"
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="data in items"
            :key="data.id"
            @click="data.child.length === 0 ? URL(data.id, data.flag) : ''"
            :ripple = "false"
            class="font-weight-bold text-capitalize black--text"
            min-width="96"
            text
          >
          <v-menu 
          offset-y
          open-on-hover
          :close-on-content-click="true"
          transition="slide-y-transition"
          >
          <template v-slot:activator="{ on, attrs }">
           <v-icon 
           color ="secondary"
           left 
           v-if="data.id === 'login'">
          mdi-shield-account-variant
          </v-icon> <span v-bind="attrs"
            v-on="on">{{ data.title }} <v-icon v-if="data.child.length > 0">mdi-menu-down</v-icon></span>
          </template>
          <v-list
            v-if="data.child.length > 0"
            dense
            class="mt-4">
            <v-list-item
              v-for="(item, index) in data.child"
              :key="index"
              @click="URL(item.id, item.flag)"
            >
              <v-list-item-title><v-icon>{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
          </v-menu>
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
        icon
      >
      <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
      @close="drawer=false"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        { id: 'beranda', title: 'Beranda', child: [] },
         { id: 'produk', title: 'Produk', child: [
          {id: 'asuransi-penjaminan', title: 'Asuransi Keuangan dan Penjaminan', icon:'mdi-bank', flag: 'produk'},
          {id: 'asuransi-umum', title: 'Asuransi Umum', icon:'mdi-car-hatchback', flag: 'produk'},
          {id: 'asuransi-kesehatan', title: 'Asuransi Kesehatan', icon:'mdi-hospital-building', flag: 'produk'},
        ]},
        { id: 'tentang', title: 'Tentang Kami', child: [
          {id: 'tentang-kami', title: 'Visi Misi', icon:'mdi-bullseye'},
          {id: 'struktur-perusahaan', title: 'Struktur', icon:'mdi-sitemap'},
          {id: 'sejarah-istpro', title: 'Sejarah', icon:'mdi-domain'},
          {id: 'produk', title: 'Produk', icon:'mdi-shield-check-outline'},
        ]},
        { id: 'layanan', title: 'Layanan', child: [
          {id: 'asuransi-rekanan', title: 'Asuransi Rekanan', icon:'mdi-store-outline'},
        ]},
        // { id: 'berita', title: 'Berita', child: [] },
        // { id: 'kontak', title: 'Kontak', child: [] },
        // { id: 'login', title: 'AkunKu', child: [] },
      ],
      ShowMenu: false,
    }),
    computed: {
      active_tab() {
        return this.$route.meta.tabs
      }
    },
    methods: {
      URL (id, flag) {
        if (id !== '') {
          if (flag === 'produk') {
            this.$router.push({name: 'produk-category', params: { url: id }})
          } else {
            this.$router.push({name: id}).catch(()=>{})
          }
        }
      },
    }
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
